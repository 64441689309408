import Transformer from './Transformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} documents
 */
export default class DocumentTransformer extends Transformer {

	table = 'horse_document'

	async transform (document) {
		return {
			'document_id': document.document_id,
			'document_name': document.document_name,
			'document_filename': document.document_filename,
			'document_date': document.document_date,
		}
	}
}
