import Constants from '../../config.local.js'
import { EventBus } from 'EventBus';
import Medias from "@/mixins/Medias.js"
import Navigation from "@/mixins/Navigation.js"
import Common from '../assets/js/common.js'
import DocumentTransformer from '@/assets/js/dexie/transformers/DocumentTransformer'

var DocumentMixin = {
    mixins: [Medias, Navigation],

    data() {
        return {
			ordonnance_type_file_accepted: "image/jpeg, image/jpg, image/png, application/pdf",
			
			// Android only
			asking_write_access: false,
			got_write_access: false,
			interracting_data: null,
			action_name: '',

			events_tab: {
				// Hook android - si on a un event d'acceptation de droits d'accès caméra ou fichiers
				"AndroidNative::SystemEvent": (params) => {
					if(params.status && params.status == "authorized") {
						return this.onPermAccepted(params)
					}
				}
			}
        }
    },
	created() {
		this.preinit_mixin()
	},
	methods: {
		async preinit_mixin() {
			if(Constants.IS_AND_RUNNING) {
				const write_perm_status = await this.getDocumentWritePermissionStatus()
				this.got_write_access = (write_perm_status === "authorized")
			}
			else {
				this.got_write_access = true
			}
		},

        /* Retourne le contenu du document à afficher */
        getDocumentFile: async function(document_filename) {
			return this.$sync.loadOnDemandFile(document_filename)
        },

        loadDocument: async function(document_id) {
			return this.$storage.db.t('horse_document')
			.then(table => {
				return table.get(parseInt(document_id))
			})
			.then(document => {
				return DocumentTransformer.process(document)
			})
        },

        updateDocument: async function(params, document_id) {
			return this.$storage.db.t('horse_document')
			.then(table => {
				return table.update(parseInt(document_id), params)
			})
        },

        /* Ajout d'un document pour une séléction de chevaux et/ou d'actes */
        addHorseDocument: async function(horse_ids, document_name, document_date, document_type, file, actes_ids = []) {
			return this.$storage.db.transaction(
				'rw',
				['_files', 'horse_document', 'horse_document_lien', 'actes_document_lien'],
				async () => {
					// Envoyer le fichier
					const filename = await this.$sync.uploadFile(file)
		
					// Enregistrer le horse_document
					const document_id = Common.getNegativeId()
					await this.$storage.db.t('horse_document')
					.then(table => {
						return table.add({
							document_id,
							document_filename: filename,
							document_documenttype: document_type,
							document_name,
							document_date: Date.parseTz(document_date),
							document_order: 0
						})
					})
		
					// Enregistrer le lien cheval/document
					const document_liens = []
					if(horse_ids.length == 0) {
						document_liens.push({
							documentlien_id: Common.getNegativeId(),
							documentlien_document: document_id,
							documentlien_valide: 1
						})
					}
					horse_ids.forEach(horse_id => {
						document_liens.push({
							documentlien_id: Common.getNegativeId(),
							documentlien_horse: parseInt(horse_id),
							documentlien_document: document_id,
							documentlien_valide: 1
						})
					})
					await this.$storage.db.t('horse_document_lien')
					.then(table => {
						return table.bulkAdd(document_liens)
					})

					// Enregistrer le lien actes/document
					const actesdocument_liens = []
					actes_ids.forEach(actes_id => {
						actesdocument_liens.push({
							actesdocumentlien_id: Common.getNegativeId(),
							actesdocumentlien_actes: parseInt(actes_id),
							actesdocumentlien_document: document_id,
							actesdocumentlien_valide: 1
						})
					})
					await this.$storage.db.t('actes_document_lien')
					.then(table => {
						return table.bulkAdd(actesdocument_liens)
					})
				}
			)
        },

        /* On dévalide une liste d'id de horse_document_lien */
        deleteDocuments: async function(document_lien_ids) {
			document_lien_ids = this.$sync.replaceWithReplicated('horse_document_lien', document_lien_ids)

			return this.$storage.db.t('horse_document_lien')
			.then(table => {
				return table.where('documentlien_id').anyOf(document_lien_ids)
			})
			.then(col => {
				return col.invalid()
			})
		},

		callGeneratedRegistreElevage: async function(){
			const params = { }
			const url = this.constructRoute(Constants.REGISTRY_GENERATE_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("TiersMixin::callGeneratedRegistreElevage", url)
			if(result) return result.retour
			return null
		},
		

		/*
		 * PERMISSIONS MANAGEMENT
		 * ANDROID ONLY
		 */
		/* Pour Android, retourne "authorized" ou "unauthorized" si les droits ont été accordés à l'appli ou non */
		async getDocumentWritePermissionStatus() {
			return new Promise((res, _) => {
				window.nativeComm.fetchNative(status => {
					res(status)
				}, null, 'checkForStoragePermission')
			})
		},

		askPermissionAndroid() {
			if(this.asking_write_access) return
			this.asking_write_access = true

			window.nativeComm.fetchNative((res => {
				this.asking_write_access = false
				if(res.status == "authorized") this.gettingSuccessfulPermissionAndroid()
				else this.gettingUnsuccessfulPermissionAndroid()

			}), null, 'askForStorageAccess', true)
		},

		gettingSuccessfulPermissionAndroid() {
			this.got_write_access = true
			return this[this.action_name](this.interracting_data)
		},

		gettingUnsuccessfulPermissionAndroid() {
			EventBus.$emit('TableAction::stopSpin')
			this.failureToast('registry.ask_and_doc_access_failure')
		},

		onPermAccepted() {
			return false
		},

		async previewPdf(documentBase64, callback=()=>false) {
			if(Constants.IS_AND_RUNNING) {
				const write_perm = await this.getDocumentWritePermissionStatus()

				if(write_perm == "authorized") {
					window.nativeComm.fetchNative(() => {
						callback(documentBase64)
					}, documentBase64, 'previewPdfDocument')
				}
				else {
					this.interracting_data = documentBase64
					this.action_name = "previewPdf"
					this.askPermissionAndroid()
				}
			}
			else if(Constants.IS_IOS_RUNNING) {
				window.nativeComm.fetchNative(() => {
					callback(documentBase64)
				}, {
					base64: documentBase64
				}, 'previewPdfFile')
			}
			else {
				return callback(documentBase64)
			}
		}
	}
}

export default DocumentMixin
